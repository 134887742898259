<template>
  <v-card elevation="4" max-width="996" width="100%" rounded="lg" class="otp" color="white">
    <v-card class="mx-auto px-7 px-md-0 py-9 py-md-16" max-width="384" :width="'100%'" tile flat>
      <v-img class="mb-5" heigth="40" max-width="184" src="@/assets/img/logo-long.svg"></v-img>
      <div class="text-start mb-6">
        <v-btn :to="`/${url}`" text color="gray">
          <v-icon v-if="!$vuetify.rtl" left>mdi-chevron-left mdi-24px</v-icon>
          {{ $t('btn.back') }}
          <v-icon class="ms-2" v-if="$vuetify.rtl" left>mdi-chevron-left mdi-24px</v-icon>
        </v-btn>
      </div>
      <div class="f30 font-weight-bold mb-2">{{ $t('otp.title') }}</div>
      <div class="gray--text mb-5">{{ $t('otp.desc') }}</div>
      <v-form @submit.prevent="login" lazy-validation>
        <v-text-field
          class="field64"
          v-model="otp"
          :error-messages="otpErrors"
          outlined
          required
          color="primary"
          height="64"
          maxLength="6"
        ></v-text-field>
        <!-- <v-otp-input
          class="field64 text-h5 otp-input justify-center"
          :error-messages="otpErrors"
          v-model="otp"
          outlined
          color="primary"
          height="64"
          length="6"
        ></v-otp-input>
        <div style="height: 15px" class="mt-n2">
          <div v-for="item in otpErrors" :key="item" class="v-messages__message text-caption error--text text-start">{{ item }}</div>
        </div> -->
        <v-btn :disabled="otp.length < 6" depressed type="submit" block height="64" class="primary mb-9 mt-1">{{ $t('btn.next') }}</v-btn>
      </v-form>
      <div class="text-center text-body-2 gray--text">
        {{ $t('otp.didntCode') }} <span @click="resend" class="primary--text link">{{ $t('btn.resent') }}</span>
      </div>
    </v-card>
  </v-card>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: this.$title(`${this.$route.name}`),
    };
  },
  data() {
    return {
      otp: '',
      error: [],
    };
  },
  methods: {
    async login() {
      this.error = [];
      await this.$store
        .dispatch('otp', { sid: sessionStorage.getItem('sid'), otp: this.otp })
        .then((res) => {
          sessionStorage.setItem('jwt_token', res.data.jwt_token);
          this.$store.dispatch('getProfile').then(() => {
            setTimeout(() => {
              if (this.profile.is_profile_completed) {
                this.$router.push('/');
                // } else if (this.profile.birthday == null || !this.profile.first_name || !this.profile.last_name) {
                //   this.$router.push('/info');
                // } else if (this.profile.location == null) {
                //   this.$router.push('/question/1');
                // } else if (!this.profile.preferred_market_sectors.length) {
                //   this.$router.push('/question/2');
                // } else if (this.profile.monthly_income_from == null || this.profile.monthly_income_to == null) {
                //   this.$router.push('/question/3');
                // } else if (!this.profile.income_sources.length) {
                //   this.$router.push('/question/4');
                // } else if (this.profile.invest_other_assets == null) {
                //   this.$router.push('/question/5');
              } else {
                this.$router.push('/info');
              }
            }, 300);
          });
        })
        .catch((e) => {
          this.error = e.response.data.error;
        });
    },
    async resend() {
      this.error = [];
      await this.$store
        .dispatch('otpResend', { sid: sessionStorage.getItem('sid') })
        .then((res) => {
          sessionStorage.removeItem('sid');
          setTimeout(() => {
            sessionStorage.setItem('sid', res.data.sid);
          }, 200);
        })
        .catch((e) => {
          this.error = e.response.data.error;
        });
    },
  },
  computed: {
    url() {
      return this.$route.params.page;
    },
    profile() {
      return this.$store.getters.profile;
    },
    otpErrors() {
      const errors = [];
      this.error.find((item) => item == 'sid__required') && errors.push(this.$t('inputError.sidRequired'));
      this.error.find((item) => item == 'bad_credentials') && errors.push(this.$t('inputError.otpNotFound'));
      this.error.find((item) => item == 'opt__expired') && errors.push(this.$t('inputError.otpExists'));
      this.error.find((item) => item == 'otp__not_found') && errors.push(this.$t('inputError.otpNotFound'));
      this.error.find((item) => item == 'action__limit_exceeded') && errors.push(this.$t('inputError.limitExceeded'));
      return errors;
    },
  },
  destroyed() {
    sessionStorage.removeItem('sid');
  },
};
</script>

<style lang="scss">
#app .otp {
  .v-card {
    text-align: center;
  }
  .field64 input {
    text-align: center;
    font-size: 24px;
  }
  @media screen and (max-width: 599px) {
    border-radius: 0 !important;
    box-shadow: none !important;
    min-height: 100vh;
    .v-card {
      text-align: start;
    }
  }
}
</style>
